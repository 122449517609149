@import "fonts";
.menu {
  &--desktop {
    margin-top: 35px;
  }
  &--desktop-logo {
    width: 180px;
    padding-top: 2px;
    margin-top: -125px;
  }

  &--desktop-wrapper {
    margin-top: 78px !important;
  }

  &--mobile-logo {
    height: auto;
    padding-top: 10px;

    @include mq($from: mobile) {
    width: 28%;
    padding-top: 7px;
    left: 35%;}

    @include mq($from: tablet) {
    width: 12%;
    padding-top: 8px;
    left: 40%;
    }
  }

  &--desktop li a {
    &:hover {
      color: $link-color;
    }
  }
}

.header-right {
  .menu--desktop-wrapper {
    margin-top: 0px !important;
  }
  .display-row {
    margin-top: 20px !important;
    @include mq($from: desktop) {
      margin-top: 25px !important;
    }
  }
}

h1, h2, h3, .heading--large, .card__title {
  font-family: $primary-font !important;
  line-height: 1.15;
}

.sticky--header {
  height: 75px;
  @include mq($from: desktop) {
  height: 175px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 520px) {
  .menu--mobile-logo {
    padding-top: 15px !important;
    width: 15% !important;
    left: 42% !important;
  }
}

@media only screen and (max-width: 335px) and (min-width: 0px) {
  #mce-EMAIL {
    min-width: 261px !important;
  }

  .menu--mobile-logo {
    padding-top: 15px;
  }

}

@media only screen and (max-width: 335px) and (min-width: 0px) {
  .menu--mobile-logo {
    width: 32%;
    padding-top: 15px;
    left: 32%;
  }
}
@media only screen and (max-width: 1100px) and (min-width: 1024px) {
  .menu--desktop-wrapper li {
    margin-right: 30px;
    font-size: $menu-font-size !important;
  }
}
@media only screen and (max-width: 1320px) and (min-width: 1024px) {
  .sticky--header {
    height: 115px;
  }

  .header-right .display-row {
    margin-top: 10px !important;
  }

  .menu--desktop-wrapper li {
    margin-right: 30px;
    font-size: 1.125rem;
  }
  .menu--desktop-logo {
    width: auto;
  }
  .menu--desktop-wrapper {
    margin-top: 90px !important;
  }
}
